.signInContainer {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
  }
  
  .signInCard {
    background-color: #14649A;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    color: #ffffff;
  }
  
  .signInButton {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #14649A;
    width: 100%;
    margin-top: 10px;
  }
  
  .signInButton:hover,
  .signInButton:focus {
    background-color: #0f4d7a;
    border: 2px solid #0f4d7a;
    color: #ffffff;
  }
  
  .form-label {
    color: #ffffff;
  }
  