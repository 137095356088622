.videoContainer {
    position: relative;
    height: 100vh;
    max-height: 1000px; /* adjust to your desired maximum height */
    overflow: hidden;
  }
  
  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover; /* Add this property */
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
  }

  .textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 0;
}

.overlay-text {
    color: #14649A;
    text-align: center;
}

.titleClass {
    font-size: 50px;
    font-weight: 700;
    color: #14649A;
}

