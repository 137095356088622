.researchJumbotron {
    background-color: #14649A;
    color: white;
    padding: 50px 0;
    margin-bottom: 30px;
  }
  
  .researchCard {
    background-color: #f8f9fa;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
  }
  
  .researchCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }
  
  .card-img-top {
    object-fit: cover;
    height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    color: #14649A;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 16px;
    color: #6c757d;
  }
  
  .mb-4 {
    margin-bottom: 30px;
  }
  