.solutionsJumbotron {
    background-color: #14649A;
    color: white;
    padding: 50px 0;
    margin-bottom: 30px;
  }
  
  .serviceCard {
    height: 100%;
  }
  
  .imageSize {
    width: 100%; /* This will make the image take up 100% of its parent container's width */
    height: 500px; /* Set a specific height value */
    object-fit: cover; /* This will make the image cover the full area of the box */
  }

  .serviceCard {
    /* Your other styles... */
    display: flex;
    flex-direction: column;
  }
  
  .serviceCardBody {
    flex: 1 0 auto;
  }