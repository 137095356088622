.contactUsJumbotron {
    background-color: #14649A;
    color: white;
    padding: 50px 0;
    margin-bottom: 30px;
  }
  
  .contactForm {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 30px;
  }
  
  .formLabel {
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  .formControl {
    font-size: 1.1rem;
  }
  
  .submitButton {
    background-color: #14649A;
    border: none;
    color: #ffffff;
  }
  
  .submitButton:hover,
  .submitButton:focus {
    background-color: #0f4d7a;
    border: none;
    color: #ffffff;
  }
  