/* src/components/Footer.module.css */

.footer {
    background-color: #fff;
    color: #333;
    padding: 1rem 0;
  }
  
  .footer p {
    text-align: center;
    margin: 0;
  }
  