.navbar {
    background-color: #f5f5f5;
}
  
.logo {
    width: 40px;
    height: auto;
}
  
.companyName {
    color: #333;
    margin-left: 10px;
    text-decoration: none;
}
  
.navbar .navbar-nav {
    margin-left: 0;
}
  

.btn-primary {
    background-color: #14649A;
}



  