.aboutJumbotron {
    background-color: #14649A;
    color: white;
    padding: 50px 0;
    margin-bottom: 30px;
  }
  
  .mb-4 {
    margin-bottom: 30px;
  }
  